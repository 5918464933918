var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppTable',{staticClass:"reconciliation-import-download-table",attrs:{"primaryKey":"id","columns":_vm.tableColumns,"rows":_vm.tableRows},scopedSlots:_vm._u([_vm._l((_vm.tableColumnsSlot),function(column){return {key:column,fn:function(slotProps){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getRowError(slotProps)),expression:"getRowError(slotProps)"}],key:column,class:[
                'reconciliation-import-download-table__cell',
                { 'reconciliation-import-download-table__cell_error': _vm.getRowIsError(slotProps) }
            ],on:{"dblclick":function($event){return _vm.setCellEditMode(slotProps)}}},[(_vm.getIsCellEditMode(slotProps))?_c('BaseTextInput',{ref:"BaseTextInput",refInFor:true,attrs:{"value":_vm.cellEditData.cellValue,"canClear":false},on:{"update:value":function($event){return _vm.$set(_vm.cellEditData, "cellValue", $event)},"onBlur":function($event){return _vm.onEditCell(slotProps)}}}):(!_vm.getIsCellDifference(slotProps))?_c('div',[_vm._v(" "+_vm._s(_vm.getRowValue(slotProps))+" ")]):_c('div',{staticClass:"reconciliation-import-download-table__cell-difference"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Данные из предфакта'),expression:"'Данные из предфакта'"}],class:{ 
                        'reconciliation-import-download-table__cell-difference_not-setted': _vm.getIsCellDifferenceNotSetted('pre_fact', slotProps),
                        'reconciliation-import-download-table__cell-difference_setted': _vm.getIsCellDifferenceSetted('pre_fact', slotProps)
                    },on:{"click":function($event){return _vm.setDifference('pre_fact', slotProps)}}},[_vm._v(" "+_vm._s(_vm.getCellDifferencePreFact(slotProps))+" ")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Данные из файла'),expression:"'Данные из файла'"}],class:{ 
                        'reconciliation-import-download-table__cell-difference_not-setted': _vm.getIsCellDifferenceNotSetted('revise', slotProps),
                        'reconciliation-import-download-table__cell-difference_setted': _vm.getIsCellDifferenceSetted('revise', slotProps)
                    },on:{"click":function($event){return _vm.setDifference('revise', slotProps)}}},[_vm._v(" "+_vm._s(_vm.getCellDifferenceRevise(slotProps))+" ")])])],1)]}}}),{key:"more_btn",fn:function(slotProps){return [_c('div',{staticClass:"cell-freeze"},[_c('BaseDropdown',{staticClass:"cell-dropdown",attrs:{"padding":0,"is-fixed":false,"position":"right","style-list":_vm.dropdownStyles},on:{"changeVisible":_vm.onChangeVisibleDropdown},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('BaseButtonIcon',{staticClass:"table-dropdown-button cell-dropdown__prev-btn",attrs:{"iconName":"tabler-icon-dots-vertical","tooltipText":""}})]},proxy:true},(_vm.isShowDropdown)?{key:"content",fn:function(){return [_c('div',{staticClass:"cell-dropdown__container"},[_c('BaseButton',{attrs:{"view":"simple","form":"oval","size":"small"},on:{"click":function($event){return _vm.onRemoveRow(slotProps)}}},[_vm._v(" Удалить ")])],1)]},proxy:true}:null],null,true)})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }