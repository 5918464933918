<template>
    <div class="reconciliation-import-attach">
        <div class="reconciliation-import-attach__section">
            <MenuTabList
                :tabList="tabList"
                :tabIdActive="tabIdActive"
                :isLoading="isLoading"
                tabMode="default"
                @onClickTab="onClickTab"
            />
        </div>
        <!-- <div class="reconciliation-import-attach__section">
            <ReconciliationInfo :infoText="tabDescription" />
        </div> -->
        <div class="reconciliation-import-attach__section">
            <BasePreloaderSkeleton v-if="isLoading"/>
            <AttachFile
                :file="file" 
                @change="onChangeFile"
            />
        </div>
    </div>
</template>

<script>
import MenuTabList from '../../MenuTabList/MenuTabList.vue';
// import ReconciliationInfo from './ReconciliationInfo.vue';
import BasePreloaderSkeleton from '../../Base/BasePreloaderSkeleton.vue';
export default {
    name: 'ReconciliationImportAttach',
    components: {
        MenuTabList,
        // ReconciliationInfo,
        BasePreloaderSkeleton
    },
    props: {
        tabList: {
            type: Array,
            default: () => ([])
        },
        tabDescription: {
            type: String,
            default: ''
        },
        tabIdActive: {
            type: String,
            default: ''
        },
        file: {
            type: File,
            default: null
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClickTab(tabId = '') {
            this.$emit('onClickTab', tabId);
        },
        onChangeFile(file = null) {
            this.$emit('onChangeFile', file);
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-import-attach {
        &__section {
            position: relative;
            margin-bottom: 20px;
        }
    }
</style>