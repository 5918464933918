<template>
    <div class="reconciliation-import-download">
        <div class="reconciliation-import-download__section">
            <div class="reconciliation-import-download__actions-block">
                <div class="reconciliation-import-download__action">
                    <BasePreloaderSkeleton v-if="isLoading"/>
                    <BaseButton
                        view="border"
                        @click="toPrevStep"
                    >
                        Назад
                    </BaseButton>
                </div>
                <div class="reconciliation-import-download__action">
                    <BasePreloaderSkeleton v-if="isLoading"/>
                    <BaseButton
                        view="secondary"
                        @click="onRunMatch"
                    >
                        Повторная проверка
                    </BaseButton>
                </div>
            </div>
        </div>
        <!-- <div class="reconciliation-import-download__section">
            <ReconciliationInfo :infoText="tabDescription" />
        </div> -->
        <div class="reconciliation-import-download__section">
            <MenuTabList
                :tabList="tabList"
                :tabIdActive="tabIdActive"
                :isLoading="isLoading"
                tabMode="default"
                @onClickTab="onClickTab"
            />
            <div class="reconciliation-import-download__table">
                <BasePreloaderSkeleton
                    v-if="isLoading"
                    class="reconciliation-import-download__table-preloader"
                />
                <ReconciliationImportDownloadTable
                    :tableColumns="tableColumns"
                    :tableRows="tableRows"
                    @editCell="onEditCell"
                    @removeRow="onRemoveRow"
                    @setDifference="onSetDifference"
                />
            </div>
        </div>
    </div>
</template>

<script>
import MenuTabList from '../../MenuTabList/MenuTabList.vue';
// import ReconciliationInfo from './ReconciliationInfo.vue';
import ReconciliationImportDownloadTable from './ReconciliationImportDownloadTable.vue';
import BaseButton from '../../Base/BaseButton.vue';
import BasePreloaderSkeleton from '../../Base/BasePreloaderSkeleton.vue';
export default {
    name: 'ReconciliationImportDownload',
    components: {
        MenuTabList,
        // ReconciliationInfo,
        ReconciliationImportDownloadTable,
        BaseButton,
        BasePreloaderSkeleton
    },
    props: {
        tabDescription: {
            type: String,
            default: ''
        },
        tabList: {
            type: Array,
            default: () => ([])
        },
        tabIdActive: {
            type: String,
            default: ''
        },
        tableColumns: {
            type: Array,
            default: () => ([])
        },
        tableRows: {
            type: Array,
            default: () => ([])
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.$notify.dNotify({
            type: 'warning',
            title: 'Редактирование данных *',
            description: 'Пройдите повторную проверку после редактирования или удаления данных',
            duration: Infinity,
            position: 'bottom-right'
        });
    },
    methods: {
        toPrevStep() {
            this.$emit('toPrevStep');
        },
        onClickTab(tabId = '') {
            this.$emit('onClickTab', tabId);
        },
        onRunMatch() {
            this.$emit('runMatch');
        },
        onEditCell(cellEditData = {}) {
            this.$emit('editCell', cellEditData);
        },
        onRemoveRow(rowId = '') {
            this.$emit('removeRow', rowId, this.tabIdActive);
        },
        onSetDifference(difference = {}) {
            this.$emit('setDifference', difference);
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-import-download {
        &__section {
            margin-bottom: 20px;
        }

        &__actions-block {
            display: flex;
        }

        &__table {
            position: relative;
            margin-top: 20px;

            &-preloader {
                z-index: 1;
            }
        }

        &__action {
            // width: 80px;
            position: relative;
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }
</style>