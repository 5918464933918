<template>
    <AppTable 
        primaryKey="id" 
        :columns="tableColumns"
        :rows="tableRows"
        class="reconciliation-import-download-table"
    >
        <template
            v-for="(column) in tableColumnsSlot"
            v-slot:[column]="slotProps"

        >
            <div
                :key="column" 
                :class="[
                    'reconciliation-import-download-table__cell',
                    { 'reconciliation-import-download-table__cell_error': getRowIsError(slotProps) }
                ]"
                v-tooltip="getRowError(slotProps)"
                @dblclick="setCellEditMode(slotProps)"
            >
                <BaseTextInput
                    v-if="getIsCellEditMode(slotProps)"
                    :value.sync="cellEditData.cellValue"
                    :canClear="false"
                    ref="BaseTextInput"
                    @onBlur="onEditCell(slotProps)"
                />
                <div v-else-if="!getIsCellDifference(slotProps)"> 
                    {{getRowValue(slotProps)}}
                </div>
                <div 
                    v-else
                    class="reconciliation-import-download-table__cell-difference"
                >
                    <div 
                        v-tooltip="'Данные из предфакта'"
                        :class="{ 
                            'reconciliation-import-download-table__cell-difference_not-setted': getIsCellDifferenceNotSetted('pre_fact', slotProps),
                            'reconciliation-import-download-table__cell-difference_setted': getIsCellDifferenceSetted('pre_fact', slotProps)
                        }"
                        @click="setDifference('pre_fact', slotProps)"
                    >
                        {{ getCellDifferencePreFact(slotProps) }}
                    </div>
                    <div 
                        v-tooltip="'Данные из файла'"
                        :class="{ 
                            'reconciliation-import-download-table__cell-difference_not-setted': getIsCellDifferenceNotSetted('revise', slotProps),
                            'reconciliation-import-download-table__cell-difference_setted': getIsCellDifferenceSetted('revise', slotProps)
                        }"
                        @click="setDifference('revise', slotProps)"
                    >
                        {{ getCellDifferenceRevise(slotProps) }}
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:more_btn="slotProps">
            <div class="cell-freeze">
                <BaseDropdown
                    class="cell-dropdown"
                    :padding="0"
                    :is-fixed="false"
                    position="right"
                    :style-list="dropdownStyles"
                    @changeVisible="onChangeVisibleDropdown"
                >
                    <template #preview>
                        <BaseButtonIcon
                            class="table-dropdown-button cell-dropdown__prev-btn"
                            iconName="tabler-icon-dots-vertical"
                            tooltipText=""
                        />
                    </template>

                    <template #content v-if="isShowDropdown">
                        <div class="cell-dropdown__container">
                            <BaseButton
                                view="simple"
                                form="oval"
                                size="small"
                                @click="onRemoveRow(slotProps)"
                            >
                                Удалить
                            </BaseButton>
                        </div>
                    </template>
                </BaseDropdown>
            </div>
        </template>
    </AppTable>
</template>

<script>
import AppTable from '../../Table2/Table.vue';
import BaseTextInput from "@/components/Base/BaseTextInput.vue";
import BaseDropdown from "@/components/Base/BaseDropdown";
import BaseButtonIcon from "@/components/Base/BaseButtonIcon";
import BaseButton from "@/components/Base/BaseButton";
export default {
    name: 'ReconciliationImportDownloadTable',
    components: {
        AppTable,
        BaseTextInput,
        BaseDropdown,
        BaseButtonIcon,
        BaseButton
    },
    props: {
        tableColumns: {
            type: Array,
            default: () => ([])
        },
        tableRows: {
            type: Array,
            default: () => ([])
        },
    },
    data: () => ({
        cellEditData: {
            cellId: '',
            cellValue: '',
            tableData: {}
        },
        dropdownStyles: {
            minWidth: '100px',
            top: '0',
            right: '45px',
            boxShadow: '0 0 10px 0 #aaa',
        },
        isShowDropdown: false,
    }),
    computed: {
        tableColumnsSlot() {
            return this.tableColumns.filter(tableColumn => Boolean(tableColumn?.isSlot ?? false)).map(tableColumn => String(tableColumn?.prop ?? ''));
        }
    },
    methods: {
        getRowValue(slotProps = {}) {
            const {cell = {}} = slotProps;
            return String(cell?.value ?? '');
        },
        getRowError(slotProps = {}) {
            const {cell = {}} = slotProps;
            return String(cell?.error ?? '');
        },
        getRowIsError(slotProps = {}) {
            return this.getRowError(slotProps) !== '';
        },
        setCellEditMode(slotProps = {}) {
            this.cellEditData.cellId = this.getCellIdEdit(slotProps);
            this.cellEditData.cellValue = this.getCellValueEdit(slotProps);
            this.cellEditData.tableData = { ...slotProps };

            this.$nextTick(() => this.$refs?.BaseTextInput[0]?.onFocus());
        },
        getIsCellEditMode(slotProps = {}) {
            return this.cellEditData.cellId === this.getCellIdEdit(slotProps);
        },
        getIsCellDifference(slotProps = {}) {
            return slotProps?.cell?.difference;
        },
        getIsCellDifferenceNotSetted(differenceType = '', slotProps = {}) {
            return slotProps?.cell?.difference?.differenceSelected && slotProps?.cell?.difference?.differenceSelected !== differenceType;
        },
        getIsCellDifferenceSetted(differenceType = '', slotProps = {}) {
            return slotProps?.cell?.difference?.differenceSelected && slotProps?.cell?.difference?.differenceSelected === differenceType;
        },
        getCellDifferencePreFact(slotProps = {}) {
            return String(slotProps?.cell?.difference?.difference?.pre_fact ?? '');
        },
        getCellDifferenceRevise(slotProps = {}) {
            return String(slotProps?.cell?.difference?.difference?.revise ?? '');
        },
        setDifference(differenceType = '', slotProps = {}) {
            const rowId = String(slotProps?.row?.id ?? '');
            const columnProp = String(slotProps?.column?.prop ?? '');
            const differenceData = slotProps?.cell?.difference ?? {};

            this.$emit('setDifference', { rowId, columnProp, differenceData, differenceType });
        },
        onEditCell(slotProps = {}) {
            if (this.cellEditData.cellValue !== this.getCellValueEdit(slotProps))
                this.$emit('editCell', { ...this.cellEditData });
            
            this.$nextTick(() => this.setCellEditMode({}));
        },
        onRemoveRow(slotProps = {}) {
            this.$emit('removeRow', String(slotProps?.row?.id ?? ''));
            this.onChangeVisibleDropdown(false);
        },
        getCellIdEdit(slotProps = {}) {
            return `${slotProps?.column?.prop ?? ''}|${slotProps?.row?.id ?? ''}`;
        },
        getCellValueEdit(slotProps = {}) {
            return String(slotProps?.cell?.value ?? '');
        },
        onChangeVisibleDropdown(newVal) {
            this.isShowDropdown = newVal;
        },
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-import-download-table {
        max-height: calc(100vh - 290px);

        &__cell {
            cursor: pointer;
            width: 100%;

            &_error {
                color: #F84967;
            }

            &-difference {
                display: flex;
                justify-content: space-between;
                color: #F84967;

                &_not-setted {
                    text-decoration: line-through;
                }

                &_setted {
                    color: #000;
                }
            }
        }
    }

    .cell-dropdown {
        ::v-deep .dropdown__triangle {
            top: 12px !important;
            right: -18px !important;
            border-left: 9px solid #fff !important;
            border-bottom-color: transparent !important;
            z-index: 6 !important;
        }

        ::v-deep .dropdown {
            box-shadow: 0 3px 15px #21242d17 !important;
        }

        &__container {
            position: relative;
            background: #fff;
            border-radius: 6px;
            overflow: hidden;
            z-index: 5;
            padding: 10px;

            button {
                padding: 5px 10px;
                font-weight: 500;
                color: #ADB0BB;
                transition: all .4s ease-out;

                &:hover {
                    color: #000;
                }
            }
        }
    }
</style>