<template>
    <div class="page-reconciliation">
        <MenuTabList
            :tabList="tabList"
            :actionsList="actionsListRender"
            :isActionItemHover="false"
            routePathPrev="/buyerdesk"
            class="page-reconciliation__menu-tab-list"
            @onImport="onImport"
        />
        <ReconciliationModalImport
            v-if="isImport"
            @onCloseModal="onCloseModalImport"
        />
        <router-view></router-view>
    </div>
</template>

<script>
import MenuTabList from '../components/MenuTabList/MenuTabList.vue';
import ReconciliationModalImport from '../components/Reconciliation/ReconciliationModals/ReconciliationModalImport.vue';
export default {
    name: 'PageReconciliationV2',
    components: {
        MenuTabList,
        ReconciliationModalImport
    },
    data: () => ({
        tabList: [
            {
                id: 'kit',
                name: 'Наборы',
                routeName: 'reconciliationKits',
                routePath: '/reconciliation/kits',
            },
            {
                id: 'offers',
                name: 'Предложения',
                routeName: 'reconciliationOffers',
                routePath: '/reconciliation/offers',
            },
            {
                id: 'approved',
                name: 'Предфакт',
                routeName: 'reconciliationApproved',
                routePath: '/reconciliation/approved',
            },
            {
                id: 'fact',
                name: 'Факт',
                routeName: 'reconciliationFact',
                routePath: '/reconciliation/fact',
            }
        ],
        actionsList: [
            {
                id: 'import',
                icon: 'IconImport',
                tooltip: 'Загрузить Excel',
                emitName: 'onImport'
            },
        ],
        actionsListMap: {
            reconciliationKits: [],
            reconciliationOffers: [],
            reconciliationApproved: ['download', 'import'],
            reconciliationFact: ['download']
        },
        isImport: false
    }),
    computed: {
        routerNameCurrent() {
            return this.$route.name;
        },
        actionsListRender() {
            const actionsListIds = this.actionsListMap[this.routerNameCurrent] ?? [];
            const actionsListRender = this.actionsList.filter(action => actionsListIds.includes(String(action?.id ?? '')));
            return actionsListRender;
            // return (this.routerNameCurrent === 'reconciliationApproved' || this.routerNameCurrent === 'reconciliationFact') ? [...this.actionsList] : [];
        }
    },
    methods: {
        onImport() {
            this.isImport = true;
        },
        onCloseModalImport() {
            this.isImport = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    .page-reconciliation {
        height: 100%;
        padding: 0px 40px;
        display: flex;
        flex-direction: column;

        &__menu-tab-list {
            background: #fff;
            margin: 0 -40px 0 -40px;
            padding: 30px 40px 0 40px;
        }
    }
</style>
